import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { applicationapi } from "../../api";

interface DocumentData {
  name?: string;
  description?: string;
  url?: string;
}

interface ResponseItemType {
  names: string;
  isLoading: boolean;
  isError: boolean;
  data: DocumentData[];
  custom_domain: string;
}

const initialState: ResponseItemType = {
  names: "documentation",
  isLoading: false,
  isError: false,
  data: [],
  custom_domain: ''
};

export const Documentationdata = createAsyncThunk<DocumentData[]>(
  "getdocumentation",
  async (_, thunkAPI) => {
    const idToken = sessionStorage.getItem("id_token");
    const url = "/documentation_api";
    try {
      const response = await applicationapi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("Response:", response);
      return response.data.data; // Ensure this returns an array of DocumentData
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            return thunkAPI.rejectWithValue({
              message: "Incorrect Token. Please try again.",
              status: 401,
            });
          case 404:
            return thunkAPI.rejectWithValue({
              message: "Resource not found.",
              status: 404,
            });
          case 500:
            return thunkAPI.rejectWithValue({
              message: "Internal server error. Please try again later.",
              status: 500,
            });
          default:
            return thunkAPI.rejectWithValue({
              message:
                error.response?.data?.data?.message || "Failed to fetch data",
              status: error.response.status,
            });
        }
      } else {
        return thunkAPI.rejectWithValue({
          message: "Network error or server did not respond.",
          status: null,
        });
      }
    }
  }
);


const Getdocumentation = createSlice({
  name: "documentation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Documentationdata.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(Documentationdata.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload?.response;
        state.custom_domain = action.payload?.url
      })
      .addCase(Documentationdata.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        if (action.payload) {
          console.error(action.payload);
        }
      });
  },
});


export default Getdocumentation.reducer;

import React, { useEffect } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import yaml from "yaml";

const Resume = `openapi: 3.0.1
info:
  title: Zita API
  description: |
    **Introduction**
    - Welcome to the Zita Standalone API! This suite of AI-powered services is designed to revolutionize the recruitment process by automating and enhancing the evaluation of candidates. It's ideal for recruitment agencies, HR departments, and any organization looking to optimize their hiring process with data-driven insights.

    **Getting Started**: 
    - To begin using the Zita API, you'll need to register for an API key. This key will authenticate your requests. Visit our developer portal to sign up and receive your key.
    
    **Authentication**: 
    - **API Key**: Securely authenticate your requests using your unique API key. Include this key in the header of your API calls: 
    
    **Rate Limits**:
    - The Zita API permits up to **100** requests per minute per user. If you exceed this limit, you will receive a **429** Too Many Requests response. Please contact us if you need higher limits. 
    
    **API Services**: 
    - Below is a list of available API services, including their endpoints, supported methods, and example requests and responses.
    
    **Versioning**
    - The Zita API is currently at version 1. We will maintain backward compatibility by introducing changes in new versions. We recommend specifying the API version in your requests: **Accept-Version: v1** 
    
    **SDKs and Libraries**
    - For easier integration, SDKs are available for the following programming languages: 
      - Python 
      - JavaScript 
    
    **FAQs**
      
    - **Q:** How do I update my API key?  
      **A:** Log into your developer account on our portal and navigate to the API section to regenerate your key.
      
    - **Q:** What formats do you accept for data uploads?  
      **A:** We accept data in JSON format. Ensure your 'content-type' is set to 'application/json' in your request headers.
   
    **Support**:
    - If you encounter any issues or have further questions, please contact our support team at support@zita.ai.
    
    
    
    



servers:
  - url: https://uotxnvwte6.execute-api.us-east-1.amazonaws.com/prod
    description: API Server
    version: 1.0.0
components:
  securitySchemes:
    ApiKeyAuth:
      type: apiKey
      in: header
      name: x-api-key

paths:
  /comparitive_analysis:
    post:
      tags:
        - Comparative Analysis 
      summary: Perform Comparative Analysis
      description: |
        **Description**: 
        - This service compares candidates across various attributes, providing actionable insights to support decision-making. By analyzing diverse data points, it aids in identifying the most suitable candidates based on objective criteria. 
        > **Sample Image**:
        - The following image demonstrates how the API service integrates with the application called Zita, showcasing the interaction. It provides a step-by-step visualization of how the API works in real-time, enabling seamless connectivity with your system.
        
        ![](/Images/comparitive_analysis.gif)
         
        **Sample Input**:
        \`\`\`json
        Endpoint: /api/comparitive_analysis 
        Method: POST 

        Request Body: 
        {
          "jd"   : (binary file data),
          "resume1" : (binary file data),
          "resume2" : (binary file data),
          "resume3" : (binary file data),
          "Roles and Responsibilities" : "True or False",
          "Industry-Specific Experience" : "True or False",
         " Domain Specific Experience":"True or False",
          "Skills":"True or False",
          "Experience":"True or False",
          "Certification":"True or False",
          "Technical Tools and Languages":"True or False",
          "Location Alignment":"True or False",
          "Soft Skills":"True or False",
          "Cultural Fit":"True or False",
          "Qualification":"True or False",
          "References and Recommendation":"True or False"
        }
        \`\`\`
        **Response**:
        \`\`\`json
        {
          "success": true,
          "data": {
              "response": {
                  "ElliotAlderson": {
                      "Total matching percentage": 70,
                      "Roles and Responsibilities": 8,
                      "Industry-Specific Experience": 6,
                      "Domain Specific Experience": 7,
                      "Skills": 8,
                      "Experience": 7,
                      "Technical Tools and Languages": 8,
                      "Qualification": 8,
                      "Cultural Fit": 8,
                      "Pros": [
                          "Strong experience in full-stack development, including the MEAN stack, aligns well with the job description",
                          "Good proficiency in Python, Flask, and SQLAlchemy matches the job requirements",
                          "Experience in Agile environments and with product demos is a plus"
                      ],
                      "Cons": [
                          "Limited specific experience with some of the required technical tools and languages, such as Debian-based Linux distributions, RDBMS installation and configuration, and Docker scripts"
                      ]
                  },
                  "DavidBaker": {
                      "Total matching percentage": 60,
                      "Roles and Responsibilities": 7,
                      "Industry-Specific Experience": 5,
                      "Domain Specific Experience": 6,
                      "Skills": 7,
                      "Experience": 6,
                      "Technical Tools and Languages": 7,
                      "Qualification": 7,
                      "Cultural Fit": 7,
                      "Pros": [
                          "Extensive experience in leading and developing teams, demonstrating leadership and project management skills",
                          "Certified Scrum Master with experience in Agile environments aligns with the job description",
                          "Proficient in a wide range of technical tools and languages, including MongoDB, Express.js, AngularJS, Swift, Java, and Python"
                      ],
                      "Cons": [
                          "Limited experience in some specific technical tools and languages, such as Debian-based Linux distributions, RDBMS installation and configuration, and Docker scripts",
                          "Less direct experience in the required role compared to the other candidate"
                      ]
                  }
              }
          }
        }
        \`\`\`
      operationId: performComparativeAnalysis
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                jd:
                  type: string
                  format: binary
                  description: Job description file for comparative analysis
                resume1:
                  type: string
                  format: binary
                  description: First resume for comparative analysis
                resume2:
                  type: string
                  format: binary
                  description: Second resume for comparative analysis
                resume3:
                  type: string
                  format: binary
                  description: Third resume for comparative analysis
                Roles and Responsibilities:
                  type: string
                  description: Include roles and responsibilities in the analysis
                  default: ''
                Industry-Specific Experience:
                  type: string
                  description: Include industry-specific experience in the analysis
                  default: ''
                Domain Specific Experience:
                  type: string
                  description: Include domain-specific experience in the analysis
                  default: ''
                Skills:
                  type: string
                  description: Include skills in the analysis
                  default: ''
                Experience:
                  type: string
                  description: Include experience in the analysis
                  default: ''
                Certification:
                  type: string
                  description: Include certification in the analysis
                  default: ''
                Technical Tools and Languages:
                  type: string
                  description: Include technical tools and language in the analysis
                  default: ''
                Location Alignment:
                  type: string
                  description: Include location alignment in the analysis
                  default: ''
                Soft Skills:
                  type: string
                  description: Include soft skills in the analysis
                  default: ''
                Cultural Fit:
                  type: string
                  description: Include cultural fit in the analysis
                  default: ''
                Qualification:
                  type: string
                  description: Include qualification in the analysis
                  default: ''
                References and Recommendation:
                  type: string
                  description: Include reference and recommendation in the analysis
                  default: ''
      responses:
        '200':
          description: Comparative analysis performed successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: true
        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters
        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.
        '403':
          description: Forbidden
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.
        '404':
          description: User Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.
        '409':
          description: Conflict (User Already Exists)
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User already exists.

        '413':
          description: Content Too Large
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Request entity too large.

        '415':
          description: Unsupported Media Type
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Unsupported media type.

        '429':
          description: Too Many Requests (OTP Limit Exceeded)
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: OTP limit exceeded. Please try again later.
            
        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  error:
                    type: string
                    example: Internal server error
  /matching:
    post:
      tags:
        - AI Matching Analysis 
      summary: Match Resume with Job Description
      description: |
        **Description**: 
        - Leverages artificial intelligence to match candidates with job descriptions effectively. This service considers candidates’ skills and experiences to find the best fit for specific job roles. 
        > **Sample Image**:
        - The following image demonstrates how the API service integrates with the application called Zita, showcasing the interaction. It provides a step-by-step visualization of how the API works in real-time, enabling seamless connectivity with your system.
        
        ![](/Images/matching_analysis.gif)
        
        **Sample Input**:
        \`\`\`json
        Endpoint: /api/matching 
        Method: POST 

        Request Body: 
        {
          "resume": (binary file data),
          "jd": (binary file data),
          "profile_matching":[{"Skills":20},{"Technical Tools and Languages":20},{"Roles and Responsibilities":20},{"Soft Skills":10},{"Experience":10},{"Educational Qualifications":20}],
          "enhanced_matching":  [{"Industry-Specific Experience":20},{"Domain-Specific Experience":20},{"Location Alignment":20},{"Certification":20},{"Cultural Fit":10},{"References and Recommendation":10}]
        }
        \`\`\`
        **Response**:
        \`\`\`json
        {
          "success": true,
          "data": {
              "enhanced_compatibility": [
                  {
                      "title": "Industry-Specific Experience",
                      "percentage": 14.0,
                      "reason": "Elliot's experience in the medical and startup industries is valuable, but the job description does not specify a preference for these sectors."
                  },
                  {
                      "title": "Domain-Specific Experience",
                      "percentage": 12.0,
                      "reason": "The job requires specific experience with Linux, cloud deployment, and database management, which are not highlighted in Elliot's resume."
                  },
                  {
                      "title": "Certification",
                      "percentage": 10.0,
                      "reason": "No certifications are mentioned in Elliot's resume, which could be beneficial for the job."
                  },
                  {
                      "title": "Location Alignment",
                      "percentage": 20.0,
                      "reason": "Elliot is based in the United States, which aligns with the job's location in Chennai, India."
                  },
                  {
                      "title": "Cultural Fit",
                      "percentage": 8.0,
                      "reason": "Elliot's experience in diverse teams and Agile environments suggests a good cultural fit, but there is no specific information about alignment with GyanData's culture."
                  },
                  {
                      "title": "References and Recommendation",
                      "percentage": 0.0,
                      "reason": "No information is provided about Elliot's references or recommendations."
                  }
              ],
              "enhanced_match_score": 64.0,
              "profile_compatibility": [
                  {
                      "title": "Skills",
                      "percentage": 16.0,
                      "reason": "Elliot has strong skills in MEAN stack, Java, and Python, which are relevant to the job. However, the job requires specific proficiency in Debian-based Linux, RDBMS, Docker, and other tools not mentioned in the resume."
                  },
                  {
                      "title": "Roles and Responsibilities",
                      "percentage": 18.0,
                      "reason": "Elliot's previous roles align well with the Lead Developer position, demonstrating experience in full-stack development and Agile environments."
                  },
                  {
                      "title": "Experience",
                      "percentage": 8.5,
                      "reason": "Elliot has over 5 years of experience in software development, which meets the job's requirement of at least 3 years of experience."
                  },
                  {
                      "title": "Educational Qualifications",
                      "percentage": 20.0,
                      "reason": "Elliot holds a Bachelor's degree in Computer Science, which meets the job's requirement for an undergraduate or postgraduate degree in any engineering discipline."
                  },
                  {
                      "title": "Technical Tools and Languages",
                      "percentage": 15.0,
                      "reason": "Elliot is proficient in several relevant programming languages and frameworks, but lacks specific experience with the tools mentioned in the job description."
                  },
                  {
                      "title": "Soft Skills",
                      "percentage": 9.0,
                      "reason": "Elliot demonstrates strong teamwork, communication, and problem-solving skills, which are essential for the Lead Developer role."
                  }
              ],
              "profile_match_score": 86.5
          }
        }
        \`\`\`

      operationId: matchResumeWithJD
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                resume:
                  type: string
                  format: binary
                  description: Resume file to be matched
                jd:
                  type: string
                  format: binary
                  description: Job description file to be matched
                profile_matching:
                  type: string
                  description: JSON string containing profile matching criteria
                  default: ''
                enhanced_matching:
                  type: string
                  description: JSON string containing enhanced matching criteria
                  default: ''
      responses:
        '200':
          description: Resume matched with job description successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: true
        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters
        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.
        '403':
          description: Forbidden
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.
        '404':
          description: User Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.
        '409':
          description: Conflict
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User already exists.
        '413':
          description: Content Too Large
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Request entity too large.
        '415':
          description: Unsupported Media Type
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Unsupported media type.
        '429':
          description: Too Many Requests
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: OTP limit exceeded. Please try again later.
        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  data:
                    type: object
                    properties:
                      error:
                        type: string
                        example: Internal server error.
  /interview_questions:
    post:
      tags:
        - AI Interview Question Generation
      summary: Generate Interview Questions
      description: |
        **Description**: 
        - Automatically generates customized interview questions based on the specifics of the job and the profiles of the candidates. This ensures that the interview process is tailored and relevant. 
        > **Sample Image**:
        - The following image demonstrates how the API service integrates with the application called Zita, showcasing the interaction. It provides a step-by-step visualization of how the API works in real-time, enabling seamless connectivity with your system.
        
        ![](/Images/interview_question.gif)
        
        **Sample Input**:
        \`\`\`json
        Endpoint: /api/interview_questions 
        Method: POST 

        Request Body: 
        {
          "resume"   : (binary file data),
          "jd"       : (binary file data),
          "criteria" : [{"level":"Hard","type":"IQ Based","count":"3"},{"level":"Hard","type":"Coding","count":"1"},{"level":"Hard","type":"Behavioral","count":"1"}],
          "role"     : "Front-End Developer",
          "summary"  : "Frontend developers are responsible for the creation and implementation of web-related projects and maintenance"
        }
        \`\`\`
        **Response**:
        \`\`\`json
        {
          "success": true,
          "data": {
              "response": [
                {
                    "question": "How would you optimize a SQL query to improve performance on a large dataset?",
                    "level": "Hard",
                    "type": "IQ Based",
                    "answer": "I would use indexing, query optimization tools, and analyze execution plans to identify and resolve performance bottlenecks."
                },
                {
                  "question": "Explain the concept of multi-threading and how it can be implemented in Python.",
                  "level": "Hard",
                  "type": "IQ Based",
                  "answer": "Multi-threading allows concurrent execution of tasks. In Python, it can be implemented using the 'threading' module to run multiple threads simultaneously."
                },
                {
                "question": "Describe a complex problem you solved using dynamic programming.",
                "level": "Hard",
                "type": "IQ Based",
                "answer": "I solved a resource allocation problem by breaking it down into smaller subproblems and using memoization to store and reuse intermediate results."
                },
                {
                  "question": "Write a Python function to implement a binary search algorithm.",
                  "level": "Hard",
                  "type": "Coding",
                  "answer": "python def binary_search(arr, x):  low, high = 0, len(arr) - 1    
                             while low <= high:   mid = (low + high) // 2     
                            if arr[mid] < x:    low = mid + 1      
                            elif arr[mid] > x:  high = mid - 1        
                            else:  return mid    
                            return -1"
                },
                {
                  "question": "Can you describe a time when you had to lead a team through a challenging project? How did you handle it?",
                  "level": "Hard",
                  "type": "Behavioral",
                  "answer": "I provided clear direction, delegated tasks based on team members' strengths, and maintained open communication to ensure everyone was aligned and motivated."
              },
            ]
          }
        }      
        \`\`\`
        
      operationId: generateInterviewQuestions
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                jd:
                  type: string
                  format: binary
                  description: Job description file for generating interview questions
                resume:
                  type: string
                  format: binary
                  description: Resume file for generating interview questions
                criteria:
                  type: string
                  description: JSON string containing criteria for interview questions
                  default: ''
                role:
                  type: string
                  description: Role for the interview
                  default: ''
                summary:
                  type: string
                  description: Summary of the role
                  default: ''
      responses:
        '200':
          description: Interview questions generated successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: true
        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters
        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.
        '403':
          description: Forbidden
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.
        '404':
          description: Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.
        '409':
          description: Conflict
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User already exists.
        '413':
          description: Content Too Large
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Request entity too large.
        '415':
          description: Unsupported Media Type
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Unsupported media type.
        '429':
          description: Too Many Requests
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: OTP limit exceeded. Please try again later.
        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  data:
                    type: object
                    properties:
                      error:
                        type: string
                        example: Internal server error.
  /profile_summary:
    post:
      tags:
        - Profile Summary 
      summary: Generate Profile Summary
      description: |
        **Description**: 
        - Provides a concise summary of key candidate details for quick evaluation. This service highlights essential information, facilitating a faster screening process. 
        > **Sample Image**:
        - The following image demonstrates how the API service integrates with the application called Zita, showcasing the interaction. It provides a step-by-step visualization of how the API works in real-time, enabling seamless connectivity with your system.
        
        ![](/Images/profile_summary.gif)
        
        **Sample Input**:
        \`\`\`json
        Endpoint: /api/profile_summary 
        Method: POST 

        Request Body: 
        {
          "resume": (binary file data),
        }
        \`\`\`
       
        **Response**: 
        \`\`\`json
        {
          "success": true,
          "data": {
              "response": {
                  "Professional Profile Analysis": {
                      "Career Trajectory": {
                          "Roles and Responsibilities": [
                              {
                                  "Role": "Assistant Hotel Manager",
                                  "Organization": "The Rosehip Hotel",
                                  "Tenure": "20XX \u00e2\u20ac\u201c Present",
                                  "Duties": "Supervise hotel staff, improve staff performance through training, attention to detail, and empathetic problem-solving methods. Assist with the preparation of staff assessments. Resolve staff and guest conflicts in a professional and courteous manner. Inventory and order business supplies. Responsible for guest billing and settling payment disputes. Admin tasks as needed including bookings, check-ins, answering phones, responding to email, and social media inquiries."
                              },
                              {
                                  "Role": "Assistant Hotel Manager",
                                  "Organization": "The Seattle Sea Home",
                                  "Tenure": "20XX \u00e2\u20ac\u201c 20XX",
                                  "Duties": "Supervised and trained hotel staff and resolved staff conflicts. Daily financial reporting. In charge of guest database and stays schedule. Point person for corporate client relations and coaching reviewing guest feedback posted online. Worked with marketing team on campaign to increase guest bookings. Assisted talent accountant with accounting tasks. Handled in-person guest complaints."
                              }
                          ],
                          "Career Progressions and Promotions": "The candidate has shown a steady progression in their career, starting as an Assistant Hotel Manager at The Rosehip Hotel and then moving to a similar role at The Seattle Sea Home."
                      },
                      "Achievements": {
                          "Significant Contributions": "The candidate has made significant contributions in their role as an Assistant Hotel Manager, where they improved staff performance, resolved conflicts, and handled guest relations.",
                          "Impact": "The candidate's work has likely improved the efficiency of hotel operations and guest satisfaction."
                      },
                      "Expertise and Skills": {
                          "Technical Skills and Proficiency Levels": {
                              "Staff Management": "Expert",
                              "Conflict Resolution": "Expert",
                              "Guest Relations": "Expert",
                              "Financial Reporting": "Proficient",
                              "Inventory Management": "Proficient",
                              "Social Media Management": "Proficient"
                          },
                          "Projects and Achievements Showcasing Skills": "The candidate's work as an Assistant Hotel Manager at The Rosehip Hotel and The Seattle Sea Home showcases their skills in staff management, conflict resolution, guest relations, financial reporting, inventory management, and social media management."
                      },
                      "Industry Engagement": {
                          "Contributions to the Industry": "No information available for this section",
                          "Professional Circles": "No information available for this section"
                      },
                      "Networking and Professional Development": {
                          "Networking Engagement": {
                              "Professional Network": "No information available for this section",
                              "Engagement in Industry-Relevant Events": "No information available for this section"
                          },
                          "Continuous Learning and Adaptability": {
                              "Ongoing Education and Skill Development": "No information available for this section",
                              "Adaptation to Industry Innovations": "No information available for this section"
                          }
                      },
                      "Education and Alignment with Career Goals": "The candidate has a Bachelor of Science in Hospitality Management from Bellows College, aligning with their career in hotel management.",
                      "Behavioral and Social Insights": {
                          "Social Media and Professional Behavior": "No information available for this section",
                          "Mobility and Ambition": "The candidate has shown mobility and ambition in their career, progressing from an Assistant Hotel Manager at The Rosehip Hotel to a similar role at The Seattle Sea Home.",
                          "Consistency": "The candidate has shown consistency in their career, with no employment discontinuities or frequent role changes."
                      },
                      "Summary and Recommendations": "The candidate has a strong background in hotel management, with expertise in staff management, conflict resolution, guest relations, financial reporting, inventory management, and social media management. They have shown a steady progression in their career and a commitment to guest satisfaction and operational efficiency."
                  }
              }
          }
        }
        \`\`\`
        
      operationId: generateProfileSummary
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                resume:
                  type: string
                  format: binary
                  description: Resume file for generating profile summary
      responses:
        '200':
          description: Profile summary generated successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: true

        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters

        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.

        '403':
          description: Forbidden
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.

        '404':
          description: Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.

        '409':
          description: Conflict
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User already exists.

        '413':
          description: Content Too Large
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Request entity too large.

        '415':
          description: Unsupported Media Type
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Unsupported media type.

        '429':
          description: Too Many Requests
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: OTP limit exceeded. Please try again later.

        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  data:
                    type: object
                    properties:
                      error:
                        type: string
                        example: Internal server error.
  /jd_generation:
    post:
      tags:
        - AI JD Assistance 
      summary: Generate Job Description
      description: |
        **Description**: 
        - Employees AI to craft detailed and attractive job descriptions aimed at drawing in the ideal candidates. This tool helps enhance the quality and appeal of job listings. 
        > **Sample Image**:
        - The following image demonstrates how the API service integrates with the application called Zita, showcasing the interaction. It provides a step-by-step visualization of how the API works in real-time, enabling seamless connectivity with your system.
        
        ![](/Images/jd_generation_by_ai.gif)
        
        **Sample Input**:
        \`\`\`json
        Endpoint: /api/jd_generation 
        Method: POST 

        Request Body: 
        {
          "job_title": "Software Developer",
          "industry_type": "Technology",
          "min_experience": "2",
          "max_experience": "10",
          "skills": ["react", "html", "css"],
          "overview": "A Software Engineer is responsible for designing, developing, testing, and maintaining software applications or systems. They work closely with stakeholders to understand requirements, write clean and efficient code, and ensure the performance, scalability, and security of the applications they develop.",
          "nice_to_have": ["python"]
        }
        \`\`\`
        **Response**: 
        \`\`\`json
        {
          "success": true,
          "data": {
              "response": {
                  "Job Title": "Software Developer",
                  "Roles and Responsibilities": [
                      "Design, develop, and maintain web applications",
                      "Ensure high performance and scalability of applications",
                      "Collaborate with cross-functional teams to deliver cutting-edge solutions"
                  ],
                  "Minimum Experience": 2,
                  "Maximum Experience": 5,
                  "Technical Skill": [
                      "Proficiency in Python programming",
                      "Expertise in Django framework for web development",
                      "Strong understanding of ReactJS for front-end development"
                  ],
                  "Soft Skill": [
                      "Strong problem-solving skills",
                      "Excellent communication and collaboration abilities",
                      "Ability to work effectively in a team-oriented environment",
                      "Adaptability in rapidly changing technical environments"
                  ],
                  "Tools and Technologies": [
                      "Python",
                      "Django",
                      "ReactJS",
                      "Version control systems like Git",
                      "Containerization tools such as Docker",
                      "CI/CD pipelines implementation"
                  ],
                  "Skills": [
                      "Web application development",
                      "Scalability and performance optimization",
                      "Cross-functional collaboration",
                      "Understanding of modern programming practices and design patterns"
                  ],
                  "mandatory_skills": [
                      "Python programming",
                      "Django framework",
                      "ReactJS",
                      "Experience with RESTful API development",
                      "Database management systems like PostgreSQL or MySQL"
                  ],
                  "nice_to_have": [
                      "DevOps",
                      "Experience with cloud platforms (AWS, Azure)",
                      "Familiarity with container orchestration tools (e.g., Kubernetes)",
                      "Knowledge in automated testing frameworks"
                  ]
              }
          }
        }
        \`\`\`
      operationId: generateJobDescription
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                job_title:
                  type: string
                  description: Job title for the description
                  default: ''
                industry_type:
                  type: string
                  description: Industry type
                  default: ''
                min_experience:
                  type: string
                  description: Minimum experience required
                  default: ''
                max_experience:
                  type: string
                  description: Maximum experience allowed
                  default: ''
                skills:
                  type: string
                  description: List of required skills as a JSON string
                  default: ''
                overview:
                  type: string
                  description: Overview of the job role
                  default: ''
                nice_to_have:
                  type: string
                  description: List of nice-to-have skills as a JSON string
                  nullable: true
                  default: ''
            examples:
              SamplePayload:
                summary: Example payload
                value:
                  job_title: Software developer
                  industry_type: Technology
                  min_experience: 2
                  max_experience: 10
                  skills: ['react', 'html', 'css']
                  overview: A Software Engineer is responsible for designing, developing, testing, and maintaining software applications or systems. They work closely with stakeholders to understand requirements, write clean and efficient code, and ensure the performance, scalability, and security of the applications they develop.
                  nice_to_have: React
      responses:
        '200':
          description: Job description generated successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: true
        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters
        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.
        '403':
          description: Forbidden (Insufficient Resources, Invalid Content)
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.
        '404':
          description: User Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.
        '409':
          description: Conflict (User Already Exists)
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User already exists.
        '413':
          description: Content Too Large
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Request entity too large.
        '415':
          description: Unsupported Media Type
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Unsupported media type.
        '429':
          description: Too Many Requests (OTP Limit Exceeded)
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: OTP limit exceeded. Please try again later.
        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  data:
                    type: object
                    properties:
                      error:
                        type: string
                        example: Internal server error.
  /jd_parser:
    post:
      tags:
        - JD Parser
      summary: Parse Job Description
      description: |
        **Description**: 
        - Extracts and analyzes essential details from job descriptions. This parsing tool simplifies the process of understanding and comparing job requirements. 
        > **Sample Image**:
        - The following image demonstrates how the API service integrates with the application called Zita, showcasing the interaction. It provides a step-by-step visualization of how the API works in real-time, enabling seamless connectivity with your system.
        
        ![](/Images/jd_parsing.gif)
        
        **Sample Input**
        \`\`\`json
        Endpoint: /api/jd_parser 
        Method: POST 

        Request Body: 
        {
          "jd": (binary file data),
        }
        \`\`\`
        **Response**:
        \`\`\`json
        {
          "success": true,
          "data": {
              "response": {
                  "Job Role": "Lead Developer",
                  "Job Type": "Developer",
                  "Location": "Chennai, Tamil Nadu",
                  "Company": "GyanData Pvt. Ltd.",
                  "Experience": "3 years or more",
                  "Education": "Undergraduate or Postgraduate degree in any engineering discipline (preferably core)",
                  "Role Summary": "The Lead Developer will be responsible for various development tasks and should be proficient in a range of technologies and tools.",
                  "Key Responsibilities": [
                      "Proficient in Debian based Linux distribution and its associated package manager and command line tools",
                      "Deployment of VMs on any of the cloud service providers (AWS preferred)",
                      "Installation and configuration of RDBMS like MySQL/MariaDB and PostgreSQL, and in-memory cache/data store like Redis or Memcached",
                      "Database design, writing, and optimizing SQL queries",
                      "Writing Docker build/deployment scripts",
                      "Installation and configuration of NGINX or Apache-httpd",
                      "Knowledge of HTML/CSS, Javascript, visualization libraries, and BI tools (a plus but not mandatory)",
                      "Use of Git for version control",
                      "Proficient in any of the following IDEs: PyCharm for Python, Eclipse or IntelliJ for Java, VSCode for HTML/CSS/Javascript",
                      "Eager to learn and can work with minimal guidance and supervision"
                  ],
                  "Technical Skills": [
                      "Debian based Linux distribution and its associated package manager and command line tools",
                      "VM deployment on cloud service providers (AWS preferred)",
                      "RDBMS like MySQL/MariaDB and PostgreSQL, in-memory cache/data store like Redis or Memcached",
                      "Docker build/deployment scripts",
                      "NGINX or Apache-httpd",
                      "HTML/CSS, Javascript, visualization libraries, BI tools",
                      "Git for version control",
                      "IDEs: PyCharm, Eclipse, IntelliJ, VSCode",
                      "Python",
                      "Standard library and OOP in Python",
                      "Python dependency management through pip",
                      "Sphinx documentation engine",
                      "Setuptools",
                      "Pandas and Numpy",
                      "Flask framework",
                      "Jinja templating engine",
                      "SQLAlchemy ORM and Core",
                      "Celery",
                      "Production ready WSGI servers like Gunicorn or uWSGI",
                      "Java (nice to have)",
                      "Java 1.7 or higher and its standard library",
                      "Dependency and build lifecycle management through Maven or Gradle",
                      "Spring framework with focus on spring-web-mvc, spring-security, spring-validator, spring-tasks",
                      "Knowledge of spring aop (preferred but not mandatory)",
                      "Hibernate",
                      "Jackson",
                      "Apache commons math, collections, commons-lang, commons-csv, and libraries from the Apache ecosystem (preferred but not mandatory)",
                      "Any servlet containers like Tomcat or Jetty"
                  ],
                  "Soft Skills": [
                      "Eager to learn",
                      "Ability to work with minimal guidance and supervision"
                  ],
                  "Salary Range": "Not specified"
              }
          }
          }
        \`\`\`
        
      operationId: parseJobDescription
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                jd:
                  type: string
                  format: binary
                  description: Job Description file to be parsed
      responses:
        '200':
          description: Job description parsed successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: true
        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters
        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.
        '403':
          description: Forbidden
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.
        '404':
          description: User Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.
        '409':
          description: Conflict
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User already exists.
        '413':
          description: Content Too Large
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Request entity too large.
        '415':
          description: Unsupported Media Type
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Unsupported media type.
        '429':
          description: Too Many Requests
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: OTP limit exceeded. Please try again later.
        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  data:
                    type: object
                    properties:
                      error:
                        type: string
                        example: Internal server error.
  /resume_parser:
    post:
      tags:
        - Resume Parser
      summary: Parse Resume
      description: | 
        **Description**: 
        - Automates the extraction of crucial information from resumes, streamlining the candidate assessment process. This service ensures efficient and accurate resume analysis. 
        > **Sample Image**:
        - The following image demonstrates how the API service integrates with the application called Zita, showcasing the interaction. It provides a step-by-step visualization of how the API works in real-time, enabling seamless connectivity with your system.
        
        ![](/Images/resume_parser.gif)
        
        **Sample Input**:
        \`\`\`json
        Endpoint: /api/resume_parser 
        Method: POST 

        Request Body: 
        {
          "resume": (binary file data),
        }
        \`\`\`
        **Response**:
        \`\`\`json
        {
          "success": true,
          "data": {
              "response": {
                  "Name": "Lisandro",
                  "Email": "lisandro@example.com",
                  "Phone number": "716-555-0100",
                  "Professional Summary": "Assistant Hotel Manager with a warm and friendly demeanor. Skilled at conflict resolution. Team builder who is acutely attentive to employee and guest needs. Punctual problem solver and avid multitasker. Track record of being an essential part of the management team and instrumental in providing effective solutions that produce immediate impact and contribute to the establishment\\u2019s long-term success.",
                  "Job Type": "Full Time",
                  "Total years of Experience": {
                      "Years": 4,
                      "Months": 0
                  },
                  "Roles and Responsibilities": [
                      {
                          "Company": "The Rosehip Hotel",
                          "Duration": "20XX - Present",
                          "Position": "Assistant Hotel Manager",
                          "Responsibilities": [
                              "Supervise hotel staff",
                              "Improve staff performance through training, attention to detail, and empathetic problem-solving methods",
                              "Assist with the preparation of staff assessments",
                              "Resolve staff and guest conflicts in a professional and courteous manner",
                              "Inventory and order business supplies",
                              "Responsible for guest billing and settling payment disputes",
                              "Admin tasks as needed including bookings, check-ins, answering phones, responding to email, and social media inquiries",
                              "Budget management"
                          ]
                      },
                      {
                          "Company": "The Seattle SeaHome",
                          "Duration": "20XX - 20XX",
                          "Position": "Assistant Hotel Manager",
                          "Responsibilities": [
                              "Supervised and trained hotel staff and resolved staff conflicts",
                              "Daily financial reporting",
                              "In charge of guest database and stays",
                              "Point person for corporate client relations and coaching",
                              "Reviewing guest feedback posted online",
                              "Worked with marketing team on campaign to increase guest bookings",
                              "Assisted talent accountant with accounting tasks",
                              "Handled in-person guest complaints",
                              "Recruiting and hiring",
                              "Quality assurance"
                          ]
                      }
                  ],
                  "Soft skills": [
                      "Conflict resolution",
                      "Team building",
                      "Punctuality",
                      "Problem solving",
                      "Multitasking",
                      "Customer service",
                      "Inventory management",
                      "Ordering supplies",
                      "Admin tasks",
                      "Budget management",
                      "Excellent listener",
                      "Friendly",
                      "Courteous",
                      "Service oriented",
                      "Poised under pressure",
                      "Staff training and schedule",
                      "Point person for corporate client relations",
                      "Reviewing guest feedback",
                      "Worked with marketing team on campaigns",
                      "Assisting with accounting tasks",
                      "Quality assurance",
                      "Solid written and verbal communicator"
                  ],
                  "Highest Qualification": "Bachelor of Science in Hospitality Management",
                  "Qualifications": [
                      "Bachelor of Science in Hospitality Management, Bellows College, June 20XX"
                  ]
              }
          }
        }
        \`\`\`
      operationId: parseResume
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                resume:
                  type: string
                  format: binary
                  description: Resume file to be parsed
                  example: (binary)
                
      responses:
        '200':
          description: Resume parsed successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: True

        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters

        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.

        '403':
          description: Forbidden (Insufficient Resources, Invalid Content)
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.

        '404':
          description: User Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.

        '409':
          description: Conflict (User Already Exists)
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User already exists.

        '413':
          description: Content Too Large
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Request entity too large.

        '415':
          description: Unsupported Media Type
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Unsupported media type.

        '429':
          description: Too Many Requests (OTP Limit Exceeded)
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: OTP limit exceeded. Please try again later.

        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  data:
                    type: object
                    properties:
                      error:
                        type: string
                        example: Internal server error.
`;
const spec = yaml.parse(Resume);

type Props = {
  domain?: any;
};

const JdGenerationDoc = ({ domain }: Props) => {
  // Function to update all URLs in the spec dynamically
  function updateUrls(spec: { servers: { url: any; }[]; }, updateFn: { (url: any): any; (arg0: any): any; }) {
    // Update server URLs
    if (spec.servers) {
      spec.servers.forEach((swagger_server: { url: any; }) => {
        if (swagger_server.url) {
          swagger_server.url = updateFn(swagger_server.url);
        }
      });
    }
  }

  function Modify_URL(url: string) {
    if (url.includes('prod/')) {
      return url.replace('prod/', 'prod');
    }
    return url.replace('zita.ai/', 'zita.ai')
  }

  // UseEffect for Custom Domain Updation in Documentation API
  useEffect(() => {
    if (domain !== null && domain !== undefined && domain) {
      let Existing_URL = 'https://uotxnvwte6.execute-api.us-east-1.amazonaws.com/prod'
      let Custom_URL = Modify_URL(domain)
      // Dynamic function to modify the URLs (example: appending a query parameter or changing the domain)
      const newUrlFunction = (url: string) => url.replace(Existing_URL, Custom_URL);
      updateUrls(spec, newUrlFunction);
    }
  }, [domain])

  // Apply the update function

  return (
    <div>
      <style>
        {`
        /* Use stronger selectors for the pre elements */
        .swagger-ui .markdown pre {
          max-width: 800px; /* Set max width to 800px */
          max-height: 750px; /* Set max height to 750px */
          overflow-y: auto; /* Allow vertical scrolling */
          background: rgba(0, 0, 0, 0.1); /* Slightly different background for better contrast */
          padding: 10px; /* Add padding for better readability */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for better visibility */
          border-radius: 8px; /* Add rounded corners */
          display: block; /* Ensure it behaves as a block */
        }

        /* Ensuring the overall layout of the Swagger UI has enough space */
        .swagger-ui {
          margin: 20px; /* Add some margin for better spacing */
        }
        /* Custom color for json */
        .swagger-ui .markdown code, .swagger-ui .renderedMarkdown code {
          background: #000000; 
          border-radius: 4px;
          color: #5ce65c;
          font-family: monospace;
          font-size: 13px;
          font-weight: normal;
          padding: 5px 7px;
        }

          .swagger-container {
            margin: 20px auto;
            max-width: 90%;
            box-sizing: border-box;
          }

          .swagger-ui .info {
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
          }

          .swagger-ui .opblock-summary-description {
            white-space: normal; /* Prevents text from overflowing */
          }

          /* Custom horizontal layout for method call header */
          .swagger-ui .opblock-summary {
            display: flex; /* Use flexbox for horizontal layout */
            align-items: center; /* Align items vertically in the center */
            justify-content: flex-start; /* Align items to the start horizontally */
            white-space: nowrap; /* Prevent text from wrapping */
            overflow: hidden; /* Hide overflow */
            max-width: 100%; /* Ensure it uses the full width of the container */
          }

          .swagger-ui .opblock-summary-method {
            margin-right: 12px; /* Space between method and path */
            font-weight: bold; /* Make the method text bold */
            white-space: nowrap; /* Prevent wrapping for method */
          }

          .swagger-ui .opblock-summary-path {
            margin-right: 12px; /* Space between path and description */
            white-space: nowrap; /* Prevent wrapping for path */
            text-overflow: ellipsis; /* Add ellipsis if the path is too long */
            overflow: hidden; /* Hide any overflowing text */
            max-width: 300px; /* Set a maximum width for the path */
            min-width: 150px; /* Set a minimum width for the path to avoid collapse */
          }

          .swagger-ui .opblock-summary-description {
            flex: 1; /* Allow the description to take the remaining space */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the description is too long */
            max-width: 400px; /* Set a maximum width for the description */
            min-width: 200px; /* Set a minimum width for the description to avoid collapse */
          }

          .swagger-ui .opblock-summary-controls {
            margin-left: auto; /* Push the controls to the far right */
            display: flex; /* Use flexbox for any control buttons */
            align-items: center; /* Align controls vertically in the center */
            white-space: nowrap; /* Prevent controls from wrapping */
          }

          /* SVG icons */
          .swagger-ui svg {
            flex-shrink: 0; /* Prevent icons from shrinking */
          }

          /* Static header setup for response table */
          .swagger-ui .response-col_description {
            width: 300px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          .swagger-ui .response-col_status {
            width: 80px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          .swagger-ui .response-col_links {
            width: 100px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          /* Fix table headers to ensure consistent layout */
          .swagger-ui .table th {
            white-space: nowrap; /* Prevent wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis for overflow */
          }

          .swagger-ui .table td {
            white-space: nowrap; /* Prevent wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis for overflow */
          }

          /* Ensure the table itself has a fixed layout */
          .swagger-ui .table {
            table-layout: fixed; /* Ensure fixed layout */
            width: 100%; /* Use full width */
          }

          /* Override the problematic rule for parameters-col_description */
          .swagger-ui .parameters-col_description {
            margin-bottom: 0; /* Remove the margin if it causes issues */
            width: auto; /* Set width to auto to allow proper resizing */
            white-space: normal; /* Allow text wrapping */
            overflow-wrap: break-word; /* Break long words */
          }


          .swagger-ui .opblock-summary-description {
            white-space: normal; /* Prevents text from overflowing */
          }
          /* Hide "No links" text */
          .swagger-ui .response-col_links i {
            display: none;
          }

          /* Optionally hide the entire "Links" column if needed */
          .swagger-ui .response-col_links {
            display: none;
          }
         
        `}
      </style>
      <SwaggerUI
        spec={spec}
        docExpansion="list" // Ensures all operations are expanded by default
      />
    </div>
  );
};

export default JdGenerationDoc;



import React, { useEffect, useState } from "react";
import Button from "../../uikit/Button";
import SvgEyeClose from "../../icons/SvgEyeClose";
import SvgEyeOpen from "../../icons/SvgEyeOpen";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { validateOtp, getOtp } from "../../redux/reducers/ChangePasswordSlice";
import { AppDispatch, RootState } from "../../redux/store/store";
import { notify } from "../../uikit/toast";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";
import "./ChangePasswordModal.modal.css";

interface ChangePasswordFirstFieldProps {
  setShowModal: (show: boolean) => void;
  setChangePasswordMode: (mode: boolean) => void;
  currentPwd: string;
}

interface FormValue {
  otp: string;
  newPassword: string;
  cnfPassword: string;
}

const ChangePasswordSecondField: React.FC<ChangePasswordFirstFieldProps> = ({
  setShowModal,
  setChangePasswordMode,
  currentPwd,
}): JSX.Element => {
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showCnfPassword, setShowCnfPassword] = useState<boolean>(false);
  const [currentpassword, setcurrentpassword] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [loader, setloader] = useState(false);

  const { data } = useSelector((state: RootState) => state.accountSetting);

  const dispatch = useDispatch<AppDispatch>();

  const initialValues: FormValue = {
    otp: "",
    newPassword: "",
    cnfPassword: "",
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("This field is required."),
    newPassword: Yup.string()
      .max(50,"Password must not exceed 50 characters.")
      .min(8, "Password must be at least 8 characters.")
      .required("This field is required."),
    cnfPassword: Yup.string()
      .required("This field is required.")
      .oneOf(
        [Yup.ref("newPassword")],
        "The new password and confirm password do not match."
      ),
  });

  // prevent the input white space and more then 50 inputs
  const handleInputValidation = (
    e: React.FormEvent<HTMLInputElement>,
    maxLength: number
  ) => {
    const inputElement = e.target as HTMLInputElement;
    let { value } = inputElement;

    if (value.startsWith(" ")) value = value.slice(0, 0);

    if (value.length > maxLength) value = value.slice(0, maxLength);

    inputElement.value = value;
    formik.setFieldValue(inputElement.name, value);
  };

  const onSubmit = (values: FormValue) => {
    const formData = new FormData();

    if (formik.values.otp === formik.values.newPassword) {
      formik.setErrors({
        newPassword: "New password cannot be the same as the old password.",
      });
      return;
    }

    setloader(true);
    formData.append("email", data?.email);
    formData.append("current_password", values.otp.trim());
    formData.append("new_password", values.newPassword.trim());
    dispatch(validateOtp(formData))
      .then((res: any) => {
        setloader(false);
        if (res.payload?.success) {
          notify("Password updated successfully!", "success", 3000);
          setChangePasswordMode(false);
          setShowModal(false);
          return;
        }
        // if (res.payload?.success === false) {
        //   formik.setErrors({
        //     otp: res.payload?.data.message,
        //   });
        // }

        if (res.error) {
          let errMsg: string =
            res.payload?.message || "Failed to Update Password";
          formik.setErrors({ otp: errMsg });
        } else {
          let errMsg: string = res.payload?.message || "somthing went wrong";
          formik.setErrors({ otp: errMsg });
        }
      })
      .catch((error: any) => {
        notify("somthing went wrong!", "error", 3000);
      });
  };

  const handleResendOtp = () => {
    if (isDisabled) return;
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", currentPwd);
    setIsDisabled(true);
    setTimer(60);
    dispatch(getOtp(formData))
      .then((res: any) => {
        if (res.payload?.success) {
          notify(
            "Verification code has been resent successfully.",
            "success",
            3000
          );
          return;
        }
        if (res.error) {
          let errMsg: string =
            res.payload?.message || "Failed to resent verification code";
          notify(errMsg, "error", 3000);
        } else {
          notify("somthing went wrong", "error", 3000);
        }
      })
      .catch((error: any) => {
        notify("Network error or server did not respond.", "error", 3000);
      });
  };

  const formik = useFormik<FormValue>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleCancel = () => {
    setChangePasswordMode(false);
    setShowModal(false);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsDisabled(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isDisabled]);

  useEffect(() => {
    setIsDisabled(true);
    setTimer(60);
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="position-relative">
        <label
          htmlFor="otp"
          className="form-label"
          style={{ fontWeight: 700, fontSize: "13px" }}
        >
          Current Password*
        </label>
        <div className="position-relative">
          <input
            type={currentpassword?"text":"password"}
            className="form-control rounded-1 current"
            id="otp"
            name="otp"
            onChange={formik.handleChange}
            value={formik.values.otp.trim()}
          />
          <span
            className="d-flex align-items-center position-absolute"
            style={{
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              border: "none",
              background: "transparent",
            }}
            onClick={() => setcurrentpassword(!currentpassword)}
          >
            {formik.values.otp &&
              (currentpassword ? (
                <CustomTooltip title={"Hide Password"}>
                  <SvgEyeClose />
                </CustomTooltip>
              ) : (
                <CustomTooltip title={"Show Password"}>
                  <SvgEyeOpen />
                </CustomTooltip>
              ))}
          </span>
        </div>
        <div className="d-flex justify-content-between" style={{marginBottom: formik.errors.otp ? "10px" :"20px"}}>
          <div className="errors">
            {formik.errors.otp && formik.touched.otp ? (
              <span className="text-danger">{formik.errors.otp}</span>
            ) : null}
          </div>
          {/* <div className="justify-content-end align-items-center">
            <span
              onClick={isDisabled ? undefined : handleResendOtp}
              style={{
                color: "#581845",
                fontSize: "12px",
                fontWeight: isDisabled ? 400 : 700,
                cursor: isDisabled ? "default" : "pointer",
              }}
            >
              {isDisabled ? `Resend Code in ${timer} secs` : "Resend code"}
            </span>
          </div> */}
        </div>
      </div>

      <div className="mb-2 position-relative">
        <label
          htmlFor="newPassword"
          className="form-label"
          style={{ fontWeight: 700, fontSize: "13px" }}
        >
          New Password*
        </label>
        <div className="position-relative">
          <input
            style={{
              height: "34px",
              maxWidth: "310px",
              fontSize: "13px",
              paddingRight: "35px",
              border: "1px solid #D7C7D2",
            }}
            type={showNewPassword ? "text" : "password"}
            className="form-control rounded-1"
            id="newPassword"
            name="newPassword"
            onChange={formik.handleChange}
            value={formik.values.newPassword.trim()}
            onInput={(e) => handleInputValidation(e, 51)}
          />
          <span
            className="d-flex align-items-center position-absolute"
            style={{
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              border: "none",
              background: "transparent",
            }}
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {formik.values.newPassword &&
              (showNewPassword ? (
                <CustomTooltip title={"Hide Password"}>
                  <SvgEyeClose />
                </CustomTooltip>
              ) : (
                <CustomTooltip title={"Show Password"}>
                  <SvgEyeOpen />
                </CustomTooltip>
              ))}
          </span>
        </div>
        <div style={{marginBottom: formik.errors.newPassword ? "10px" : "20px"}}>
        {formik.errors.newPassword && formik.touched.newPassword ? (
          <div className="text-danger" style={{ marginTop: "3px" }}>
            {formik.errors.newPassword}
          </div>
        ) : null}
        </div>
      </div>

      <div className="mb-2 position-relative">
        <label
          htmlFor="cnfPassword"
          className="form-label"
          style={{ fontWeight: 700, fontSize: "13px" }}
        >
          Confirm New Password*
        </label>
        <div className="position-relative">
          <input
            style={{
              height: "34px",
              maxWidth: "310px",
              fontSize: "13px",
              paddingRight: "35px",
              border: "1px solid #D7C7D2",
            }}
            type={showCnfPassword ? "text" : "password"}
            className="form-control rounded-1"
            id="cnfPassword"
            name="cnfPassword"
            onChange={formik.handleChange}
            value={formik.values.cnfPassword.trim()}
            onInput={(e) => handleInputValidation(e, 50)}
          />

          <span
            className="d-flex align-items-center position-absolute"
            style={{
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              border: "none",
              background: "transparent",
            }}
            onClick={() => setShowCnfPassword(!showCnfPassword)}
          >
            {formik.values.cnfPassword &&
              (showCnfPassword ? (
                <CustomTooltip title={"Hide Password"}>
                  <SvgEyeClose />
                </CustomTooltip>
              ) : (
                <CustomTooltip title={"Show Password"}>
                  <SvgEyeOpen />
                </CustomTooltip>
              ))}
          </span>
        </div>
        <div style={{marginBottom: formik.errors.cnfPassword ? "10px" : "20px"}}>
        {formik.errors.cnfPassword && formik.touched.cnfPassword ? (
          <div className="text-danger" style={{ marginTop: "3px" }}>
            {formik.errors.cnfPassword}
          </div>
        ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-end pt-3" style={{marginRight: "5px"}}>
        <div className="me-4">
          <Button
            name="Cancel"
            variant="cancelPrimary"
            onClick={handleCancel}
            width={"70px"}
            height={"30px"}
          />
        </div>

        {loader ? (
          <div
            className="spinner-border justify-content-center align-items-center "
            role="status"
            style={{
              width: "20px",
              height: "20px",
              border: "2px solid rgb(88, 24, 69)",
              borderRightColor: "transparent",
              margin: "0px 15px",
              marginTop: "5px",
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <Button
            name="Change"
            variant="primary"
            type="submit"
            width={"70px"}
            height={"30px"}
          />
        )}
      </div>
    </form>
  );
};

export default ChangePasswordSecondField;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getApiProducts,
  handelCheckoutApi,
  selectProductById,
} from "../../redux/reducers/ApiProductsSlice";
import { AppDispatch, RootState } from "../../redux/store/store";
import ProfileSummaryLeft from "./ProfileSummaryLeft/ProfileSummaryLeft";
import ProfileSummaryRight from "./ProfileSummaryRight/ProfileSummaryRight";
import Loader from "../../uikit/Loader";
import { Coupon_code } from "../../api/";
import { notify } from "../../uikit/toast";
export default function ProfileSummary(): JSX.Element {
  const [subTotal, setSubTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isAddon, setIsAddon] = useState<boolean>(false);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);
  const [coupon, setcoupon] = useState("");
  const [error, seterror] = useState("");
  const [promosuccess, setpromosuccess] = useState(false);
  const [couponloader, setcouponloader] = useState(false);
  const [coupon_id, setcoupon_id] = useState("");
  const [discount, setdiscount] = useState(0);
  const [addonData, setAddonData] = useState({
    title: "",
    credits: 0,
    requests: 0,
    addonTotal: 0,
  });
  const [coupon_type, setcoupon_type] = useState(0);

  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const data = useSelector((store: RootState) => selectProductById(store, id));

  const LOCAL_STORAGE_KEY: string = id!;

  // Handle checkout process
  const handelCheckout = () => {
    setcouponloader(true);
    setBtnLoader(true);
    const formData = new FormData();
    const stripe = [String(data?.monthly.stripe_id)];
    const plan_name = [String(data?.monthly.id)];
    const quantity = ["1"];
    if (isAddon) {
      if (!data?.billing_no) {
        stripe.push(String(data?.addon.stripe_id));
        quantity.push(String(Number(addonData?.credits) / 2));
        plan_name.push(String(data?.addon.id));
      } else {
        if (data?.billing_no && data?.colour === 'red') {
          stripe.push(String(data?.addon.stripe_id));
          quantity.push(String(Number(addonData?.credits) / 2));
          plan_name.push(String(data?.addon.id));
        } else {
          stripe[0] = String(data?.addon.stripe_id);
          quantity[0] = String(Number(addonData?.credits) / 2);
          plan_name[0] = String(data?.addon.id);
        }

      }
    }
    formData.append("stripe", JSON.stringify(stripe));
    formData.append("quantity", JSON.stringify(quantity));
    formData.append("plan_name", JSON.stringify(plan_name));
    coupon_id?.trim() && formData.append("coupon_id", coupon_id);
    dispatch(handelCheckoutApi(formData))
      .then((response) => {
        window.location.href = response.payload.data.url;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setBtnLoader(false);
      });
  };

  // Update total and addon status based on product colour
  useEffect(() => {
    if (data?.colour === "green" || data?.colour === "yellow") {
      setTotal(Number(subTotal));
      setIsAddon(true);
    }
  }, [subTotal, data?.colour]);

  // Fetch API products if data is not available
  useEffect(() => {
    if (!data && id) dispatch(getApiProducts());
    setTotal(Number(data?.monthly.price));
  }, [dispatch, data, id]);

  //get localstorage data
  useEffect(() => {
    const savedData = sessionStorage.getItem("dis"); // Get the saved data from localStorage
    if (savedData) {
      var data = JSON.parse(savedData);
      setpromosuccess(data.success);
      setcoupon(data.coup);
      setcoupon_id(data.code);
      setdiscount(data.disc);
      setcoupon_type(data.type);
      setTotal(data.total);
    }
  }, [total]);

  const apply2 = (val?: any) => {
    if (coupon === "") {
      seterror("This field is required");
    } else {
      // couponsavelocal();
      seterror("");
      setcouponloader(true);
      const formData = new FormData();
      const stripe = [String(data?.monthly.stripe_id)];
      const plan_name = [String(data?.monthly.id)];
      const quantity = ["1"];

      if (!data?.billing_no) {
        stripe.push(String(data?.addon.stripe_id));
        quantity.push(
          val
            ? String(Number(val / 2))
            : String(Number(addonData?.credits) / 2)
        );
        plan_name.push(String(data?.addon.id));
      } else {
        if (data?.billing_no && data?.colour === 'red') {
          stripe.push(String(data?.addon.stripe_id));
          quantity.push(
            val
              ? String(Number(val / 2))
              : String(Number(addonData?.credits / 2))
          );
          plan_name.push(String(data?.addon.id));
        } else {
          stripe[0] = String(data?.addon.stripe_id);
          quantity[0] = String(Number(addonData?.credits / 2));
          plan_name[0] = String(data?.addon.id);
        }
      }

      formData.append("stripe", JSON.stringify(stripe));
      formData.append("quantity", JSON.stringify(quantity));
      formData.append("plan_name", JSON.stringify(plan_name));
      formData.append("coupon_code", JSON.stringify(coupon));
      // Coupon_code(param)
      //   .then((res) => {})
      //   .catch((error) => {
      //     console.log("error", error);
      //   });

      Coupon_code(formData)
        .then((response) => {
          if (response.success === true) {
            setcouponloader(false);
            setpromosuccess(true);
            if (!promosuccess) {
              notify("Promo code applied successfully", "success", 3000);
            }

            setcoupon_id(response.data.code);
            setdiscount(response.data.discount_amount);
            setTotal(response.data.final);
            setcoupon_type(response.data.coupon_type);
            couponsavelocal(
              response.data.code,
              response.data.discount_amount,
              coupon,
              true,
              response.data.coupon_type,
              response.data.final
            );
          } else {
            seterror(response.data.message);
          }
        })
        .catch((error) => {
          setcouponloader(false);

          notify(
            "Failed to fetch data. Please try again later.",
            "error",
            3000
          );
        })
        .finally(() => {
          setBtnLoader(false);
          setcouponloader(false);
        });
    }
  };
  const apply = (val?: any) => {
    if (coupon === "") {
      seterror("This field is required");
    } else {
      // couponsavelocal();
      seterror("");
      setcouponloader(true);
      const formData = new FormData();
      const stripe = [String(data?.monthly.stripe_id)];
      const plan_name = [String(data?.monthly.id)];
      const quantity = ["1"];
      if (isAddon) {
        if (!data?.billing_no) {
          stripe.push(String(data?.addon.stripe_id));
          quantity.push(
            val
              ? String(Number(val) / 2)
              : String(Number(addonData?.credits) / 2)
          );
          plan_name.push(String(data?.addon.id));
        } else {
          if (data?.billing_no && data?.colour === 'red') {
            stripe.push(String(data?.addon.stripe_id));
            quantity.push(
              val
                ? String(Number(val) / 2)
                : String(Number(addonData?.credits) / 2)
            );
            plan_name.push(String(data?.addon.id));
          } else {
            stripe[0] = String(data?.addon.stripe_id);
            quantity[0] = String(val
              ? String(Number(val / 2))
              : String(Number(addonData?.credits / 2))
            );
            plan_name[0] = String(data?.addon.id);
          }
        }
      }
      formData.append("stripe", JSON.stringify(stripe));
      formData.append("quantity", JSON.stringify(quantity));
      formData.append("plan_name", JSON.stringify(plan_name));
      formData.append("coupon_code", JSON.stringify(coupon));
      // Coupon_code(param)
      //   .then((res) => {})
      //   .catch((error) => {
      //     console.log("error", error);
      //   });

      Coupon_code(formData)
        .then((response) => {
          if (response.success === true) {
            setcouponloader(false);
            setpromosuccess(true);
            if (!promosuccess) {
              notify("Promo code applied successfully", "success", 3000);
            }

            setcoupon_id(response.data.code);
            setdiscount(response.data.discount_amount);
            setTotal(response.data.final);
            setcoupon_type(response.data.coupon_type);
            couponsavelocal(
              response.data.code,
              response.data.discount_amount,
              coupon,
              true,
              response.data.coupon_type,
              response.data.final
            );
          } else {
            seterror(response.data.message);
          }
        })
        .catch((error) => {
          setcouponloader(false);

          notify(
            "Failed to fetch data. Please try again later.",
            "error",
            3000
          );
        })
        .finally(() => {
          setBtnLoader(false);
          setcouponloader(false);
        });
    }
  };

  //coupon close function
  const closeMessage = () => {
    if (data?.colour === "green" || data?.colour === "yellow") {
      setTotal(addonData?.addonTotal);
    } else {
      if (isAddon) {
        setTotal(parseInt(data?.monthly.price) + addonData?.addonTotal);
      } else {
        setTotal(parseInt(data?.monthly.price));
      }
    }
    setcoupon_type(0);
    setdiscount(0);
    setpromosuccess(false);
    seterror("");
    setcoupon("");
    setcoupon_id("");
    notify("Promo code removed successfully", "success", 3000);
    sessionStorage.removeItem("dis");
  };

  //coupon save local
  const couponsavelocal = (
    code: any,
    discounts: any,
    coupon: string,
    succ: boolean,
    type: number,
    total: number
  ) => {
    const dataToStore = {
      disc: discounts,
      code: code,
      type: type,
      coup: coupon,
      success: succ,
      total: total,
    };
    sessionStorage.setItem("dis", JSON.stringify(dataToStore));
  };

  //Addon remove and coupon apply product
  const removeAddon = () => {
    if (coupon_type === 1) {
      closeMessage();
    } else {
      seterror("");
      setcouponloader(true);
      const formData = new FormData();
      const stripe = [String(data?.monthly.stripe_id)];
      const plan_name = [String(data?.monthly.id)];
      const quantity = ["1"];
      formData.append("stripe", JSON.stringify(stripe));
      formData.append("quantity", JSON.stringify(quantity));
      formData.append("plan_name", JSON.stringify(plan_name));
      formData.append("coupon_code", JSON.stringify(coupon));
      // Coupon_code(param)
      //   .then((res) => {})
      //   .catch((error) => {
      //     console.log("error", error);
      //   });

      Coupon_code(formData)
        .then((response) => {
          if (response.success === true) {
            setcouponloader(false);
            setpromosuccess(true);

            setcoupon_id(response.data.code);
            setdiscount(response.data.discount_amount);
            setTotal(response.data.final);
            setcoupon_type(response.data.coupon_type);
            couponsavelocal(
              response.data.code,
              response.data.discount_amount,
              coupon,
              true,
              response.data.coupon_type,
              response.data.final
            );
          } else {
            seterror(response.data.message);
          }
        })
        .catch((error) => {
          setcouponloader(false);
          notify(
            "Failed to fetch data. Please try again later.",
            "error",
            3000
          );
        })
        .finally(() => {
          setBtnLoader(false);
          setcouponloader(false);
        });
    }
  };

  if (!data && id) return <Loader />;
  return (
    <>
      <div className="row g-0 ">
        <div className="col-8">
          <ProfileSummaryLeft
            data={data}
            setTotal={setTotal}
            isAddon={isAddon}
            setIsAddon={setIsAddon}
            addonData={addonData}
            setAddonData={setAddonData}
            setSubTotal={setSubTotal}
            LOCAL_STORAGE_KEY={LOCAL_STORAGE_KEY}
            discount={discount}
            seterror={seterror}
            apply={apply}
            removeAddon={removeAddon}
            promosuccess={promosuccess}
            applynew={apply2}
          />
        </div>
        <div
          style={{
            backgroundColor: "#F2E7EF",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
          }}
          className="d-flex col-4 "
        >
          <div style={{ width: "320px", marginTop: "16vh" }}>
            <ProfileSummaryRight
              total={total}
              addonData={addonData}
              data={data}
              isAddon={isAddon}
              handelCheckout={handelCheckout}
              btnLoader={btnLoader}
              setcoupon={setcoupon}
              coupon={coupon}
              error={error}
              seterror={seterror}
              apply={apply}
              promosuccess={promosuccess}
              closeMessage={closeMessage}
              couponloader={couponloader}
              discount={discount}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import { useState } from "react";
import SvgDownloadInvoice from "../../icons/SvgDownloadInvoice";
import { notify } from "../../uikit/toast";
import { BillingTableInterface } from "./BillingTypes";
import styles from "./billing.module.css";
import { downloadinvoiceapi } from "../../redux/reducers/BillingSlice";
import { AppDispatch } from "../../redux/store/store";
import { useDispatch } from "react-redux";

interface Props {
  dataList: BillingTableInterface[];
}

interface DownloadInvoiceResponse {
  payload: {
    data: any; // Replace `any` with the actual type if you know it
  };
}

const BillingTable: React.FC<Props> = ({ dataList }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const [loadingInvoices, setLoadingInvoices] = useState<{
    [key: string]: boolean;
  }>({});

  //Export Download fucntion Here
  const downloadPDF = async (url: string, id: string) => {
    try {
      // Mark this invoice as loading
      setLoadingInvoices((prev) => ({ ...prev, [id]: true }));

      const res = (await dispatch(
        downloadinvoiceapi({ invoice_id: id })
      )) as DownloadInvoiceResponse;

      const link = document.createElement("a");
      link.href = res.payload.data.download_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      notify("Invoice downloaded successfully!", "success", 3000);

      // Remove the invoice from loading state
      setLoadingInvoices((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    } catch (error) {
      console.error("Error downloading invoice:", error);

      // Ensure to remove the loader in case of error
      setLoadingInvoices((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  // Invoice Download Function
  const InvoiceDownload = async (url: string, id: string | undefined) => {
    if (url && id) {
      setLoadingInvoices((prev) => ({ ...prev, [id]: true }));
      await downloadPDF(url, id);
    }
  };

  // here its Download Invoice With Toast
  const openInNewTab = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    const filename = "hello.pdf";
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const DateObject = (timestamp: any) => {
    if (timestamp) {
      if (timestamp !== "None") {
        const dateObject = new Date(timestamp);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");// Months are 0-indexed in JavaScript, so add 1
        const day = dateObject.getDate();
        const formattedDay = day.toString().padStart(2, "0");
        return `${formattedDay}-${month}-${year}`;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const check_nextbilling = (val: any) => {
    if (val) {
      if (val === "-") {
        return false;
      }
      if (val === "None") {
        return false;
      }
      return true;
    }
    return false;
  };
  // console.log("invoice", loader);

  return (
    <div
      style={{
        maxHeight: window.innerHeight - 130,
        overflowY: "scroll",
      }}
    >
      <table
        className={`table ${styles.table}`}
        style={{ paddingLeft: "none", height: "fit-content" }}
      >
        <thead style={{ position: "sticky", top: "0" }}>
          <tr style={{ display: "flex" }}>
            <th className={styles.hpadchange} style={{ flex: "1 1 1%" }}>
              <span className={styles.title}>Billing ID</span>
            </th>
            <th className={styles.hpadchange} style={{ flex: "1.5 0 0%" }}>
              <span className={styles.title}>Service</span>
            </th>
            <th className={styles.hpadchange}>
              <span className={styles.title}>Type</span>
            </th>
            <th className={styles.hpadchange}>
              <span className={styles.title}>Payment Date</span>
            </th>
            <th className={styles.hpadchange}>
              <span className={styles.title}>Total</span>
            </th>
            <th className={styles.hpadchange}>
              <span className={styles.title}>Status</span>
            </th>
            <th className={styles.hpadchange}>
              <span className={styles.title}>Next Billing Date</span>
            </th>
            <th className={styles.hpadchange}>
              <span className={styles.invoicetitle}>Invoice</span>
            </th>
          </tr>
        </thead>
        <tbody style={{ paddingTop: 20 }} className={styles.tablebody}>
          {dataList.map((doc, index) => {
            const status = doc.paid === true ? "Paid" : "Unpaid";
            const total_amount = doc.total;
            const Total = `$ ${total_amount}`;
            const nextbillingdate = check_nextbilling(doc.next_billing_date);
            return (
              <>
                <tr key={index} className={styles.trtag}>
                  <td
                    className={styles.padchanges}
                    style={{ flex: "1 1 0%" }}
                  >
                    <span className={styles.input}>{doc.invoice}</span>
                  </td>
                  <td
                    className={styles.padchanges}
                    style={{ flex: "1.5 1 0%" }}
                  >
                    <span className={styles.input}>{doc.name}</span>
                  </td>
                  <td className={styles.padchanges}>
                    <span className={styles.overflow_input}>{doc.type}</span>
                  </td>
                  <td className={styles.padchanges}>
                    <span className={doc.payment_date ? styles.paymentdate : styles.hypen}>
                      {DateObject(doc.payment_date)}
                    </span>
                  </td>
                  <td className={styles.padchanges} style={{}}>
                    <span className={styles.input}>{Total}</span>
                  </td>
                  <td className={styles.padchanges} style={{}}>
                    <span
                      className={doc.paid === true ? styles.paid : styles.unpaid}>
                      {status}
                    </span>
                  </td>
                  <td className={styles.padchanges}>
                    <span className={nextbillingdate ? styles.billingdate : styles.hypen}>
                      {DateObject(doc.next_billing_date)}
                    </span>
                  </td>
                  <td className={styles.padchanges} style={{}}>
                    {doc.paid === true ? (
                      <>
                        {loadingInvoices[doc.invoice || ""] ? ( // Provide a fallback if doc.invoice is undefined
                          <div className={styles.downloadspin}>
                            <span
                              className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                              role="status"
                              style={{
                                marginBottom: "2px",
                                width: "15px",
                                height: "15px",
                                borderWidth: "2px",
                              }}
                            ></span>
                          </div>
                        ) : (
                          <div
                            title="Download Invoice"
                            className={styles.downloadinvoice}
                            onClick={() =>
                              InvoiceDownload(doc.invoice_pdf, doc.invoice)
                            }
                          >
                            <SvgDownloadInvoice />
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </td>{" "}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BillingTable;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../uikit/Button";
import SvgProducts from "../../../icons/SvgProducts";
import SvgApiProductsAdd from "../../../icons/SvgApiProductsAdd";
import SvgApiProductsCheck from "../../../icons/SvgApiProductsCheck";
import ProfileSummaryCard from "./ProfileSummaryCard";
import { disconnect } from "process";

type ProfileSummaryLeftProps = {
  data: {
    name: string;
    monthly: { price: number };
    addon: {
      requests: any;
      price: number
    };
    colour?: string;
  };
  isAddon: boolean;
  setIsAddon: React.Dispatch<React.SetStateAction<boolean>>;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  setSubTotal: React.Dispatch<React.SetStateAction<number>>;
  addonData: {
    title: string;
    credits: number;
    requests: number;
    addonTotal: number;
  };
  setAddonData: React.Dispatch<
    React.SetStateAction<{
      title: string;
      credits: number;
      requests: number;
      addonTotal: number;
    }>
  >;
  LOCAL_STORAGE_KEY: string;
  discount: number;
  seterror: any;
  apply: any;
  removeAddon: any;
  promosuccess: boolean;
  applynew?:any
};

export default function ProfileSummaryLeft({
  data,
  isAddon,
  setIsAddon,
  setTotal,
  setSubTotal,
  setAddonData,
  LOCAL_STORAGE_KEY,
  discount,
  seterror,
  removeAddon,
  apply,
  promosuccess,
  applynew,
}: ProfileSummaryLeftProps) {
  const [credit, setCredit] = useState<number>(2);
  const [requests, setRequests] = useState<number>(0);
  const [addonTotal, setAddonTotal] = useState<number>(0);
  const navigate = useNavigate();

  const handleAddCredits = () => {
    seterror("");
    setIsAddon(true);
    if (promosuccess) {
      const newCredit = Number(2);
      applynew(newCredit);
    }
  };
  const handleCancel = () => {
    setIsAddon(false);
    if (promosuccess) {
      removeAddon();
    }
    sessionStorage.removeItem(LOCAL_STORAGE_KEY);
    if (data?.colour === "green" || data?.colour === "yellow") {
      navigate("/api-products");
      setTotal(0);
    } else {
      setTotal(Number(data?.monthly.price));
    }
  };

  const storeDataInsessionStorage = () => {
    const dataToStore = {
      isAddon,
      credits: credit,
    };
    sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dataToStore));
  };

  const handleCreditChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCredit = Number(e.target.value);
    
    setCredit(newCredit);
    if (promosuccess) {
      apply(newCredit);
    }
  };

  useEffect(() => {
    const newRequests = credit * (data?.addon?.requests) / 2;
    const newAddonTotal = (data?.addon.price * credit) / 2;


    if (isAddon) setSubTotal(newAddonTotal);

    setRequests(newRequests);
    setAddonTotal(newAddonTotal);

    // Update the total cost in the parent when credits change
    if (isAddon)
      setTotal(Number(data?.monthly.price) + (newAddonTotal - discount));

    // Update parent with the new addon data
    setAddonData({
      title: `${data?.name} - Add On Credits`,
      credits: credit,
      requests: newRequests,
      addonTotal: newAddonTotal,
    });

    if (isAddon) storeDataInsessionStorage();
  }, [credit, data, isAddon, setAddonData, setSubTotal, setTotal]);

  // Load saved data from sessionStorage on component mount
  useEffect(() => {
    const savedData = sessionStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setCredit(parsedData.credits);
      setIsAddon(parsedData.isAddon);
    }
  }, [LOCAL_STORAGE_KEY, setIsAddon]);

  return (
    <>
      <div className="col-12 py-1 ps-2">
        <div className="d-flex  align-items-center mt-3 ms-2">
          <SvgProducts width={20} height={20} />
          <span
            className="d-flex align-items-center ms-2"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              paddingBottom: "3px",
            }}
          >
            API Products
          </span>
        </div>
        <div style={{ padding: "0px 36px 0 36px" }}>
          <span
            style={{
              fontWeight: 700,
              fontSize: "14px",
              marginRight: "5px",
              cursor: "pointer",
              color: "#581845",
            }}
            onClick={() => navigate("/api-products")}
          >
            API Products
          </span>
          <span style={{ fontSize: "14px" }}>/ {data?.name}</span>
          <p style={{ fontSize: "13px", marginTop: "10px" }}>
            Please review your details carefully before proceeding with the
            payment.
          </p>
          <div>
            <ProfileSummaryCard data={data} />
            {isAddon && (
              <>
                <div className="card mb-3 py-3">
                  <p
                    className="mx-3"
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                  >
                    <SvgApiProductsCheck /> <span>Additional Credits</span>
                  </p>
                  <div className="ps-5">
                    <div className="row row-cols-4 g-0">
                      <div className="col text-start">
                        <div style={{ fontSize: "13px", fontWeight: 700 }}>
                          Title
                        </div>
                        <div
                          style={{
                            fontSize: "13px",
                            marginTop: "4px",
                          }}
                        >
                          {data?.name} - Add On Credits
                        </div>
                      </div>
                      <div
                        className="col text-start"
                        style={{ paddingLeft: "10px" }}
                      >
                        <div style={{ fontSize: "13px", fontWeight: 700 }}>
                          Amount
                        </div>
                        <select
                          name="credit"
                          id="credit"
                          value={credit}
                          onChange={handleCreditChange}
                          style={{
                            width: "60px",
                            marginTop: "4px",
                            border: "1px solid #C9CACE",
                            height: "30px",
                            borderRadius: "3px",

                            fontSize: "13px",
                          }}
                        >
                          {[...Array(10)].map((_, index) => (
                            <option
                              style={{ fontWeight: 400 }}
                              key={index}
                              value={(index + 1) * 2}
                            >
                              $ {(index + 1) * 2}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="col text-start"
                        style={{ paddingLeft: "10px" }}
                      >
                        <div
                          style={{
                            fontSize: "13px",

                            fontWeight: 700,
                          }}
                        >
                          Requests
                        </div>
                        <div
                          style={{
                            fontSize: "13px",
                            marginTop: "4px",
                          }}
                        >
                          {requests}
                        </div>
                      </div>
                      <div
                        className="col text-start"
                        style={{ paddingLeft: "10px" }}
                      >
                        <div
                          style={{
                            fontSize: "13px",

                            fontWeight: 700,
                          }}
                        >
                          Credits
                        </div>
                        <div
                          style={{
                            fontSize: "13px",
                            marginTop: "4px",
                          }}
                        >
                          {requests}
                        </div>
                      </div>
                      <div
                        className="col text-start"
                        style={{ marginTop: "30px", marginBottom: "25px" }}
                      >
                        <div style={{ fontSize: "13px", fontWeight: 700 }}>
                          Validity
                        </div>
                        <div
                          style={{
                            fontSize: "13px",
                            marginTop: "4px",
                          }}
                        >
                          Rolling
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="d-flex justify-content-end">
              {isAddon ? (
                <Button
                  type="reset"
                  variant="cancelPrimary"
                  name={"Cancel"}
                  onClick={handleCancel}
                />
              ) : (
                <Button
                  type="submit"
                  variant="secondary"
                  name={"Add Credits"}
                  width="115px"
                  svg={<SvgApiProductsAdd />}
                  onClick={() => handleAddCredits()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

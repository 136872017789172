import React from "react";
import styles from "./apipurchaselog.module.css";
import SvgNoReport from "../../icons/SvgNoReport";
import moment from "moment";

interface PurchaseLogEntry {
  api_name: string;
  credits: string;
  description: string;
  download_url: string;
  email: string;
  expired_date: string; // Consider using Date if appropriate
  invoice: string;
  name: string;
  next_billing_date: string; // Consider using Date if appropriate
  paid: boolean;
  payment_date: string; // Consider using Date if appropriate
  total: string;
  type: string;
}

interface ApiPurchaseLogProps {
  data: PurchaseLogEntry[];
}

const ApiPurchaseLog: React.FC<ApiPurchaseLogProps> = ({ data }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className={`${styles.mainheading} `}>Billing Details</h5>

        <div className="">
          {data && data?.length === 0 ? (
            <div
              className="col-12 d-flex justify-content-center align-items-center flex-column"
              style={{ height: "302px" }}
            >
              <div style={{ padding: "0 0 10px 0" }}>
                <SvgNoReport />
              </div>
              <div style={{ color: "#999FAC", fontSize: "13px" }}>
                Looks like there are no report details available.
              </div>
            </div>
          ) : (
            <div>
              <table
                className={`table mb-0 ${styles.table}`}
                style={{ height: "302px" }}
              >
                <thead className={styles.stickyHeader}>
                  <tr>
                    <th className={styles.headingtable}>ID</th>
                    <th className={styles.headingtable}>Type</th>
                    <th className={styles.headingtable}>Payment Date</th>
                    <th className={styles.headingtable}>Expires on</th>
                    <th className={styles.headingtable}>Total</th>
                    <th className={styles.headingtable} style={{ width: '18%' }}>Next Billing Date</th>
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  {data &&
                    data?.map((item, index) => (
                      <tr key={index}>
                        <td className={styles.valuetable}>{item?.invoice}</td>
                        <td className={styles.valuetable}>{item?.type}</td>
                        <td className={styles.valuetable}>
                          {moment(item?.payment_date).format("DD-MM-YYYY")}
                        </td>
                        <td className={styles.valuetable}>
                          {" "}
                          {item?.expired_date === null ? '-' : item?.expired_date}
                        </td>
                        <td className={styles.valuetable}> $ {item?.total}</td>
                        <td className={styles.valuetable}>
                          {item?.next_billing_date === null ? "-" : item?.next_billing_date}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApiPurchaseLog;
